export default [{
    path: "/completed",
    component: () => import(/* webpackChunkName: "user" */ "@/layouts/user/UserLayout.vue"),
    children: [
        {
            path: "",
            name: "completed",
            component: () => import( /* webpackChunkName: "corfirmFly" */ "@/views/fly-completed/FlyCompleted.vue")
        }

    ]
}]