import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import es from "vuetify/src/locale/es.ts";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      options: {
        customProperties: true,
        variations: false,
      },
      light: {
        primary: "#000421",
        secondary: "#3BD4AE",
        error: "#DE2568",
        success: "#3BD4AE",
        gray: "#FAFAFA",
        pink: "#E03E78"
      },
      dark: {
        primary: "#3BD4AE",
        secondary: "#000020",
        error: "#DE2568",
        success: "#3BD4AE",
        gray: "#FAFAFA",
        pink: "#E03E78"
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es'
  },
});
