<template>
  <component :is="resolveLayoutVariant">
    <LoadingOverlay :loading="app.isLoadingOverlay" />
    <v-slide-x-transition mode="out-in">
      <router-view></router-view>
    </v-slide-x-transition>
  </component>
</template>

<script>
import BlankLayout from "@/layouts/blank/BlankLayout.vue";
import AdminLayout from "@/layouts/admin/AdminLayout.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "App",
  components: {
    BlankLayout,
    AdminLayout,
    LoadingOverlay,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(["app", "auth"]),
    resolveLayoutVariant() {
      return this.$route.meta.layout === "admin"
        ? "AdminLayout"
        : "BlankLayout";
    },
  },
  methods: {
    ...mapActions("userAuth", ["setUserAuthDocumentCount"]),
    async handleAuthChange(newValue) {
      if (!newValue) return;

      const { isLoggedIn, userDetails, token } = newValue.user;
      const includesUserAuthPermissions =
        isLoggedIn &&
        (userDetails.adminType === 0 ||
          userDetails.permissions?.includes("user-auth"));

      if (isLoggedIn && token && includesUserAuthPermissions) {
        await this.getUserAuthDocumentsCount();
      }
    },
    async getUserAuthDocumentsCount() {
      try {
        const response = await this.$axios.get(
          "/user_authentication/get_user_auth_documents_count"
        );
        // if (!response || !response.data.success) {
        //   throw new Error("Error getting user auth documents count");
        // }

        const { pending = 0, duplicated = 0 } = response?.data?.user_auth_details;
        this.setUserAuthDocumentCount({ fetched: true, pending, duplicated });
      } catch (error) {
        console.error("Error fetching user auth documents count:", error);
      }
    },
  },
  watch: {
    auth: {
      handler: "handleAuthChange",
      deep: true,
    },
  },
  sockets: {
    async connect() {
      console.info("Socket connected");
    },
    async user_auth_detail(data) {
      console.log("Received user_auth_detail data:", data);

      if (this.auth) {
        this.handleAuthChange(this.auth);
      }
    },
  },
  async created() {
    this.$vuetify.theme.dark = this.app.isThemeDark;

    if (this.auth) {
      this.handleAuthChange(this.auth);
    }
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
::-webkit-scrollbar {
  background-color: white;
}
::-webkit-scrollbar-thumb {
  background-color: #000421;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  /* background-color: #d8d8d8; */
}
::-webkit-scrollbar-thumb:active {
  /* background-color: #d8d8d8; */
}
::-webkit-scrollbar-corner {
  background-color: #d8d8d8;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}
</style>
