import { render, staticRenderFns } from "./BaseBreadCrumbs.vue?vue&type=template&id=552f8735&scoped=true&"
import script from "./BaseBreadCrumbs.vue?vue&type=script&lang=js&"
export * from "./BaseBreadCrumbs.vue?vue&type=script&lang=js&"
import style0 from "./BaseBreadCrumbs.vue?vue&type=style&index=0&id=552f8735&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "552f8735",
  null
  
)

export default component.exports