export default [{
    path: "/",
    component: () => import(/* webpackChunkName: "user" */ "@/layouts/user/UserLayout.vue"),
    children: [
        {
            path: "empty-legs",
            name: "empty-legs",
            component: () => import( /* webpackChunkName: "EmptyLegsList" */ "@/views/empty-legs/EmptyLegs.vue")
        }

    ]
}]