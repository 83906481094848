<template>
  <v-overlay :value="loading" z-index="999">
    <v-progress-circular indeterminate :size="size" color="primary"/>
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 64,
    },
  },
};
</script>

<style scoped></style>
