export default [{
    path: "/",
    component: () => import(/* webpackChunkName: "user" */ "@/layouts/user/UserLayout.vue"),
    children: [
        {
            path: "",
            name: "search-flight",
            component: () => import( /* webpackChunkName: "search" */ "@/views/search-flight/SearchFlight.vue")
        }

    ]
}]