export const DATE_FORMAT = "D [de] MMMM YYYY, h:mm a"
export const FLIGHT_TYPES = {
    DEPARTURE: 1,
    ROUND_TRIP: 2,
    EMPTY_LEG: 3,
}
export const TIME_SCHEDULES = {
  MORNING: 1,
  AFTERNOON: 2,
  EVENING: 3,
}

export const EMPTY_LEGS_STATUS = {
  ACTIVE: 1,
  INACTIVE: 2,
  EXPIRED: 3,
}

// export const SITE_KEY = "0x4AAAAAAAjDWAMHJZSie0Px"
export const SITE_KEY = process.env.VUE_APP_CF_CLIENT_TOKEN

export function toLowerCase(string) {
  return string.toLowerCase();
}

export function capitalizeFirstLetter(string) {
  return string ? string[0].toUpperCase() + string.slice(1) : "";
}

export async function getYearList (min, max, present = false) {
  const yearsList = [];
  for (var i = min; i <= max; i++) {
    yearsList.push(i);
  }
  if (present) {
    yearsList.push('Presente');
  }
  return  yearsList;
}

export function doRangesOverlap(rangeA, rangeB) {
  const { start: startA, end: endA } = rangeA;
  const { start: startB, end: endB } = rangeB;

  return (startA <= endB && startB <= endA);
}

export function generateUniqueId() {
  const now = new Date();
  return (
    now.getFullYear() +
    ("0" + (now.getMonth() + 1)).slice(-2) +
    ("0" + now.getDate()).slice(-2) +
    ("0" + now.getHours()).slice(-2) +
    ("0" + now.getMinutes()).slice(-2) +
    ("0" + now.getSeconds()).slice(-2) +
    now.getMilliseconds()
  );
}

/**
 * Validates if a given string is a valid MongoDB ObjectId.
 * @param {string} id - The string to validate.
 * @return {boolean} - Returns true if the string is a valid ObjectId, false otherwise.
 */
export function isValidMongoId(id) {
  let isValid = typeof id === "string" && /^[0-9a-fA-F]{24}$/.test(id);

  return isValid ? id : false;
}

/**
 * Validation rules for the passenger when searching a fly
 * @param {string} passengerId Id of the type of passenger to evaluate
 * @param {{[key: string]: number}} passengers list of passengers types and thei totals
 * @param {{[key: string]: string}} errors Dictionary of errros
 * @returns new dictionary of errors
 */
export function validateFlyingPassengers(passengerId, passengers, errors) {
  return {
    adults: (passengers) => {
      const newErrors = {...errors};
      // const babies = passengers.find(passenger => passenger.id === "babies")
      // const pets = passengers.find(passenger => passenger.id === "pets")
      // const adults = passengers.find(passenger => passenger.id === passengerId)

      const babies = passengers.babies
      const pets = passengers.pets
      const adults = passengers.adults

      if (adults < babies) {
        newErrors["lessBabiesThanAdults"] = "La cantidad de bebés debe ser menor o igual a la cantidad de adultos"
      } else {
        delete newErrors["lessBabiesThanAdults"]
      }

      if (pets > 0 && adults < 1 ) {
        newErrors["OneAdultWhenPets"] = "Debe haber al menos un adulto cuando viaja una mascota"
      } else {
        delete newErrors["OneAdultWhenPets"]
      }

      return newErrors
    },
    babies: (passengers) => {
      const newErrors = {...errors};
      // const babies = passengers.find(passenger => passenger.id === passengerId)
      // const adults = passengers.find(passenger => passenger.id === "adults")

      const babies = passengers.babies
      const adults = passengers.adults

      if (adults < babies) {
        newErrors["lessBabiesThanAdults"] = "La cantidad de bebés debe ser menor o igual a la cantidad de adultos"
      } else {
        delete newErrors["lessBabiesThanAdults"]
      }

      return newErrors
    },
    pets: (passengers) => {
      const newErrors = {...errors};
      // const pets = passengers.find(passenger => passenger.id === passengerId)
      // const adults = passengers.find(passenger => passenger.id === "adults")

      const pets = passengers.pets
      const adults = passengers.adults

      if (pets > 0 && adults < 1 ) {
        newErrors["OneAdultWhenPets"] = "Debe haber al menos un adulto cuando viaja una mascota"
      } else {
        delete newErrors["OneAdultWhenPets"]
      }

      return newErrors
    },
    childrens: (passengers) => {
      const newErrors = {...errors};
      return newErrors;
    }
  }[passengerId](passengers);
}

/**
 * Airplanes filters dictionary
 * @param {string} filterOption filter option to be used
 * @param {any[]} airplanes List of airplanes to sort
 * @returns sorted airplanes list
 */
export function filterAirplaneMethods(filterOption, airplanes = []) {
  const sortedList = [...airplanes]
  return {
    lowestTime: () => {
      return [...sortedList.sort((airplane1, airplane2) => {        
        const { hours: a1hours, minutes: a1minutes } = airplane1.time;
        const { hours: a2hours, minutes: a2minutes } = airplane2.time;
        if (a1hours === a2hours) {
          return a1minutes - a2minutes
        }
        return a1hours - a2hours
      })];
    },
    lowestToHighest: () => {
      return [...sortedList.sort((airplane1, airplane2) => {
        return airplane1.price - airplane2.price
      })]
    },
    highestToLowest: () => {
      return [...sortedList.sort((airplane1, airplane2) => {
        return airplane2.price - airplane1.price
      })]
    },
  }[filterOption]()
}