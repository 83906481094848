export default [
  {
    path: "*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "404" */ "@/views/errors/404.vue"),
    meta: {
      requiresAuth: false,
      permission: false,
      layout: "blank",
      title: "404",
    },
  },
];
