export default [
  {
    path: "/main",
    name: "main",
    meta: {
      requiresAuth: true,
      permission: null,
      title: "Principal",
      module: "main",
      layout: "admin",
      icon: "mdi-home",
    },
    component: () =>
      import(/* webpackChunkName: "main" */ "@/views/main/MainLayout.vue"),
    children: [
      {
        path: "",
        name: "dashboards",
        meta: {
          requiresAuth: true,
          permission: null,
          layout: "admin",
          title: "Dashboards",
          module: "main",
          adminLayoutNav: true,
          icon: "mdi-view-dashboard",
          notBreadCrumb: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "dashboards" */ "@/views/main/Dashboards.vue"
          ),
      },
    ],
  },
];
