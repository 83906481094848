<template>
  <div>
    <!-- Generales -->
    <template
      v-if="!$route.meta.notBreadCrumb && !$route.meta.customBreadCrumb"
    >
      <nav class="breadcrumbs">
        <div v-for="(crumb, index) in breadcrumbs" :key="index">
          <router-link
            :to="crumb.to"
            :class="{
              'breadcrumb-link': crumb.to,
              'breadcrumb-dark-theme': $vuetify.theme.isDark,
            }"
          >
            {{ crumb.text }}
          </router-link>
          <span
            v-if="index < breadcrumbs.length - 1"
            class="breadcrumb-separator"
            ><v-icon small>mdi-arrow-left</v-icon></span
          >
        </div>
      </nav>
      <h1 class="mt-n4 d-flex flex-row align-baseline">
        <v-icon
          v-if="$route.meta.icon"
          class="mr-2"
          :color="$vuetify.theme.isDark ? 'white' : 'primary'"
          >{{ $route.meta.icon }}</v-icon
        >
        {{ $route.meta.title }}
        <span
          class="ml-1"
          v-if="$route.meta?.socket && computedSocketValueKeyUserAuthFetched"
          >{{ computedSocketValueKeyUserAuth }}</span
        >
        <span
          class="ml-1"
          v-if="
            $route.meta?.allowedTotalDocumentsForIndexPages &&
            entireState.app.totalDocumentsForIndexPagesBreadCrumbs > 0
          "
        >
          ({{
            entireState.app.totalDocumentsForIndexPagesBreadCrumbs
              | numberFormatNoDecimal
          }})</span
        >
      </h1>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  methods: {
    fetchDataByKey(key) {
      return key;
    },
  },
  computed: {
    ...mapState({
      entireState: (state) => state, // Change 'state' to your module name if you're using modules
    }),
    // breadcrumbs() {
    //   const matchedRoutes = this.$route.matched;

    //   return matchedRoutes.map((route) => ({
    //     text: route.meta.title || "Home",
    //     to: !route.path.includes(":id")
    //       ? route.path
    //       : `${this.$route.params.id}`,
    //   }));
    // },
    breadcrumbs() {
      // Helper function to build the full route path recursively
      const buildFullPath = (matched, currentPath = "") => {
        return matched.reduce((path, route) => {
          // Append the current route path to the accumulated path
          const routePath = route.path.startsWith("/")
            ? route.path
            : `${currentPath}/${route.path}`;
          // Replace dynamic segments with actual params
          const resolvedPath = routePath
            .split("/")
            .map((segment) =>
              segment.startsWith(":")
                ? this.$route.params[segment.substring(1)] || segment
                : segment
            )
            .join("/");
          // Normalize the path by removing any trailing slashes
          return resolvedPath.replace(/\/$/, "");
        }, "");
      };

      // Map each route to its breadcrumb object and filter out duplicates
      let previousPath = "";
      return this.$route.matched
        .map((route, index, matchedRoutes) => {
          // Generate the full path for the current route using all routes up to the current index
          const toPath = buildFullPath(matchedRoutes.slice(0, index + 1));
          return {
            text: route.meta.title || "Home",
            to: toPath,
          };
        })
        .filter((breadcrumb) => {
          // Normalize and remove consecutive duplicates, considering trailing slashes
          const normalizedPath = breadcrumb.to.replace(/\/$/, "");
          if (normalizedPath === previousPath) {
            return false;
          } else {
            previousPath = normalizedPath;
            return true;
          }
        });
    },
    computedSocketValueKeyUserAuthFetched() {
      return this.entireState.userAuth.userAuthDocumentCount.fetched;
    },
    computedSocketValueKeyUserAuth() {
      if (!this.$route.meta.socket) {
        return "";
      }
      if (!this.computedSocketValueKeyUserAuthFetched) {
        return "";
      }
      const TYPE_MAPPING = {
        "userAuth.userAuthDocumentCount.pending":
          this.entireState.userAuth.userAuthDocumentCount.pending,
        "userAuth.userAuthDocumentCount.duplicated":
          this.entireState.userAuth.userAuthDocumentCount.duplicated,
      };
      if (TYPE_MAPPING[this.$route.meta.socketValueKey] <= 0) {
        return "";
      }
      return `(${TYPE_MAPPING[this.$route.meta.socketValueKey]})` || "";
    },
  },
};
</script>

<style scoped>
.breadcrumbs {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.breadcrumb-link {
  color: #000421; /* Change to your preferred link color */
  text-decoration: none;
  transition: color 0.3s;
}

.breadcrumb-link:hover {
  color: #1565c0; /* Change to your preferred link hover color */
}

.breadcrumb-separator {
  margin: 0 0.5rem;
  color: #777; /* Change to your preferred separator color */
}

.breadcrumb-dark-theme {
  color: #ffffff;
}
</style>
