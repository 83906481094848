import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

import auth from "./modules/auth";
import fly from "./modules/fly";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    auth: state.auth, // Include the 'auth' module
    fly: state.fly,
    app: {
      isMiniVariant: state.app.isMiniVariant,
      isThemeDark: state.app.isThemeDark,
    },
    // userAuth: {
    //   userAuthDocumentCount: state.userAuth.userAuthDocumentCount,
    // },
  }),
});

export default new Vuex.Store({
  state: {
    app: {
      isLoadingOverlay: false,
      isMiniVariant: false,
      isThemeDark: false,
      totalDocumentsForIndexPagesBreadCrumbs: 0
    },
  },
  mutations: {
    SET_APP(state, app) {
      state.app = { ...state.app, ...app };
    },
  },
  actions: {
    setApp({ commit }, app) {
      commit("SET_APP", app);
    },
  },
  getters: {
    app: (state) => state.app,
  },
  modules: {
    auth,
    fly
  },
  plugins: [vuexLocal.plugin],
});
