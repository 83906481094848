import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

// Importar Rutas fuera del admin Nav Rutas acá
import loginRoutes from "@/views/login/routes";
import adminRoutes from "@/views/admin-page/routes";
import errorRoutes from "@/views/errors/routes";

// Importar rutas de Modulos en admin nav acá
import mainRoutes from "@/views/main/routes";

// Importar search flight module
import searchFlightRouters from "@/views/search-flight/routes";
import airplanesRouters from "@/views/airplanes/routes";
import confirmFlyRoutes from "@/views/confirm-fly/routes";
import completedRoutes from "@/views/fly-completed/routes";
import legalsRoutes from "@/views/legals/routes";
import flyersRouters from "@/views/flyers/routes";
import emptyLegs from "@/views/empty-legs/routes";

const routes = [
  // Rutas varias
  // ...loginRoutes,
  ...adminRoutes,
  ...searchFlightRouters,
  ...airplanesRouters,
  ...confirmFlyRoutes,
  ...completedRoutes,
  ...flyersRouters,
  ...emptyLegs,
  // Módulos
  ...mainRoutes,
  // Terminos legales
  ...legalsRoutes,
  // Errores
  ...errorRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 300)
    })
  },
});

// router.beforeEach((to, _from, next) => {
//   // Get user information from the Vuex store
//   const user = store.state.auth.user;

//   // Extract permissions and adminType from user details
//   const { permissions = [], adminType = 1 } = user.userDetails; //sub admin no permission, per default

//   // Check if the route requires authentication
//   const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);

//   // Check if the route requires a specific permission
//   const needPermission = to.meta.permission;

//   // Authentication logic
//   if (requiresAuth) {
//     // Show error notification if the user lacks permission
//     if (
//       adminType !== 0 &&
//       needPermission &&
//       !permissions.includes(needPermission)
//     ) {
//       Vue.prototype.$dialog.notify.error(
//         "No tienes permiso para ver este recurso"
//       );
//       // Redirect to the main page if permission is denied
//       return next({ path: "/main" });
//     }

//     // Show error notification if the user is not logged in
//     if (!user.isLoggedIn) {
//       Vue.prototype.$dialog.notify.error("No se encuentra logueado");

//       // Redirect to the login page with a redirect query only if not already on the login page
//       if (to.name !== "login") {
//         return next({ name: "login", query: { redirect: to.fullPath } });
//       }
//     }
//   }

//   // Continue with the navigation
//   next();
// });

export default router;
