<template>
  <v-app>
    <v-app-bar
      app
      :color="!$vuetify.theme.isDark ? 'white' : 'secondary'"
      class="blur-nav"
      :class="{ 'blur-nav-dark': $vuetify.theme.isDark }"
      height="65"
    >
      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn
        text
        class="font-urbanist d-flex flex-column"
        link
        @click="$route.name !== 'admin' && $router.push({ name: 'admin' })"
        rounded
      >
        <span class="font-weight-bold mr-1">
          {{ user.userDetails.username }}
        </span>
        <span>|</span>
        <span class="ml-1">{{ user.userDetails.email }}</span>
      </v-btn>
      <v-btn @click="handleTheme()" icon v-if="$vuetify.breakpoint.lgAndUp">
        <v-icon color="primary">
          {{
            $vuetify.theme.isDark ? "mdi-weather-night" : "mdi-weather-sunny"
          }}
        </v-icon>
      </v-btn>

      <v-btn @click="logout" text v-if="$vuetify.breakpoint.lgAndUp" icon
        ><v-icon color="primary"> mdi-logout </v-icon></v-btn
      >
      <v-btn
        v-if="!$vuetify.breakpoint.lgAndUp"
        @click="handleHamburguer()"
        icon
        ><v-icon color="primary"> mdi-menu </v-icon></v-btn
      >
    </v-app-bar>

    <v-navigation-drawer
      app
      ref="drawer"
      v-model="drawer"
      class="drawer-transition white--text"
      :expand-on-hover="collapsedDrawer"
      :mini-variant="collapsedDrawer"
      :color="!$vuetify.theme.isDark ? 'primary' : 'secondary'"
      @transitionend="transitioned"
    >
      <v-list class="">
        <v-list-item
          class="d-flex flex-row justify-space-between"
          link
          @click="
            $vuetify.breakpoint.lgAndUp && (collapsedDrawer = !collapsedDrawer)
          "
        >
          <AdminLayoutNavSVG
            :isHovered="isHovered"
            :collapsedDrawer="collapsedDrawer"
          />
          <v-spacer></v-spacer>
          <!-- colapsar a mini el drawer -->
          <div class="justify-end d-flex" v-if="!$vuetify.breakpoint.lgAndUp">
            <v-btn @click="handleTheme()" icon>
              <v-icon color="error">
                {{
                  $vuetify.theme.isDark
                    ? "mdi-weather-night"
                    : "mdi-weather-sunny"
                }}
              </v-icon>
            </v-btn>
            <v-btn
              color="error"
              @click="logout"
              v-if="!$vuetify.breakpoint.lgAndUp"
              icon
            >
              <v-icon class="" color="error">mdi-logout</v-icon>
            </v-btn>
          </div>
          <v-btn icon class="" v-if="$vuetify.breakpoint.lgAndUp">
            <v-icon color="white">
              {{
                !collapsedDrawer ? "mdi-circle-double" : "mdi-circle-outline"
              }}</v-icon
            >
          </v-btn>
        </v-list-item>
      </v-list>

      <v-divider color="white"></v-divider>

      <!-- navegacion -->
      <v-list shaped flat class="custom-list">
        <v-list-group
          v-for="(module, index) in adminRoutes"
          :key="index"
          no-action
          :value="module.module === $route.meta.module"
        >
          <template v-slot:appendIcon>
            <v-icon color="white"> mdi-chevron-down </v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-icon
              ><v-icon
                :color="
                  module.module === $route.meta.module ? 'error' : 'white'
                "
              >
                {{ module.icon }}
              </v-icon></v-list-item-icon
            >
            <v-list-item-title>{{ module.title }} </v-list-item-title>
          </template>

          <v-list-item
            v-for="(child, childIndex) in module.children"
            :key="childIndex"
            :to="child.to"
            :class="{ 'custom-active': getClassForNavItem(child) }"
            dense
            class="ml-0 pl-5"
          >
            <v-list-item-icon>
              <v-icon color="white">{{ child.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              >{{ child.title }}
              <span v-if="child.socket && child.socketValueKey" class="ml-1">
                {{ handleChildSocketValueKey(child.socketValueKey) }}</span
              ></v-list-item-title
            >
          </v-list-item>
        </v-list-group>
      </v-list>
      <!-- //logoout -->
      <template v-slot:append> </template>
    </v-navigation-drawer>
    <v-main>
      <v-container
        fluid
        class="pa-3 theme-container"
        :class="{
          'dashboard-container': $route.name === 'dashboards',
          'dark-theme-container': $vuetify.theme.isDark,
        }"
      >
        <v-container>
          <BaseBreadCrumbs class="" />
        </v-container>
        <slot></slot>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import BaseBreadCrumbs from "@/components/BaseBreadCrumbs.vue";
import router from "@/router";
import AdminLayoutNavSVG from "@/components/AdminLayoutNavSVG.vue";

export default {
  components: { BaseBreadCrumbs, AdminLayoutNavSVG },
  data() {
    return {
      drawer: this.$vuetify.breakpoint.lgAndUp ? true : false,
      collapsedDrawer: this.$vuetify.breakpoint.lgAndUp ? !true : false,
      isHovered: false,
      keyTimer: null,
      keyHoldTime: { q: 312.5 },
    };
  },
  methods: {
    ...mapActions("auth", ["setUser"]),
    ...mapActions(["setApp"]),

    async logout() {
      this.setApp({
        isLoadingOverlay: true,
      });
      await this.$axios.post("/authentication/admin/logout");
      this.setUser({
        isLoggedIn: false,
        token: null,
        refresh: null,
        userDetails: {},
      });
      this.setApp({
        isLoadingOverlay: false,
      });
      this.$router.push({ name: "login" });
    },
    handleHamburguer() {
      this.drawer = !this.drawer;
      this.collapsedDrawer = false;
    },
    transitioned(e) {
      if (this.$refs.drawer.isMouseover) {
        this.isHovered = true;
        return;
      }
      this.isHovered = false;
    },
    getClassForNavItem(item) {
      const currentPath = this.$route.path;

      // Remove a trailing slash if it's present
      const cleanPath = currentPath.endsWith("/")
        ? currentPath.slice(0, -1)
        : currentPath;
      if (cleanPath === item.to) {
        return true;
      }
      return false;
    },
    handleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.setApp({ isThemeDark: this.$vuetify.theme.isDark });
    },
    handleChildSocketValueKey(socketKey) {
      const TYPE_MAPPING = {
        "userAuth.userAuthDocumentCount.pending":
          this.userAuthDocumentCount.pending,
        "userAuth.userAuthDocumentCount.duplicated":
          this.userAuthDocumentCount.duplicated,
      };
      if (TYPE_MAPPING[socketKey] <= 0) {
        return "";
      }
      return `(${TYPE_MAPPING[socketKey]})` || "";
    },
    handleKeyDown(event) {
      if (event.key === "q" || event.key === "Q") {
        if (!this.keyTimer) {
          this.keyTimer = setTimeout(() => {
            this.collapsedDrawer = !this.collapsedDrawer;
            this.keyTimer = null; // Reset the timer after firing
          }, this.keyHoldTime.q);
        }
        return;
      }
    },
    handleKeyUp(event) {
      if ((event.key === "q" || event.key === "Q") && this.keyTimer) {
        clearTimeout(this.keyTimer);
        this.keyTimer = null;
      }
    },
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState(["app"]),
    ...mapState("userAuth", ["userAuthDocumentCount"]),
    mainClasses() {
      return {
        "main-exapanded": this.drawer,
      };
    },
    adminRoutes() {
      const adminRoutes = router.options.routes.filter(
        (route) =>
          route.meta.layout === "admin" && !route.meta.excludedAdminLayoutNav
      );
      let modules = adminRoutes.map((route) => {
        return {
          module: route.meta.module,
          title: route.meta.title,
          icon: route.meta.icon,
          permissions: route.meta.permission,
          children: route.children
            ? route.children
                .map((child) => {
                  // Only include children with meta adminLayoutNav
                  if (child.meta.adminLayoutNav) {
                    return {
                      title: child.meta.title,
                      icon: child.meta.icon,
                      to:
                        route.path +
                        (child.path.length > 0 ? `/${child.path}` : ""),
                      permissions: child.meta.permission,
                      socket: child.meta.socket || null,
                      socketValueKey: child.meta.socketValueKey || null,
                    };
                  }
                  return null; // Skip non-adminLayoutNav children
                })
                .filter((child) => child !== null)
            : [],
        };
      });

      // Filter modules based on user permissions
      const user = this.user;
      if (user.userDetails.adminType > 0) {
        const permissions = user.userDetails.permissions;

        // Filter the modules themselves based on permissions
        const filteredModules = modules.filter((module) => {
          // Check if the module has permissions and if the user has these permissions
          const hasModulePermission =
            module.permissions === null ||
            permissions.includes(module.permissions);

          // Filter the children of the module based on permissions
          if (hasModulePermission && module.children) {
            module.children = module.children.filter((child) => {
              return (
                child.permissions === null ||
                permissions.includes(child.permissions)
              );
            });
            return true; // Keep the module if the user has permission
          }

          return hasModulePermission; // This will only keep modules that the user has permission for
        });

        // Assuming you want to continue using the filtered list of modules
        modules = filteredModules;
      }

      return modules;
    },
  },
  watch: {
    collapsedDrawer: function (newVal) {
      this.setApp({
        isMiniVariant: newVal,
      });
    },
  },
  created() {
    this.collapsedDrawer = this.app.isMiniVariant;
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keyup", this.handleKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
    clearTimeout(this.keyTimer);
  },
};
</script>

<style lang="scss" scoped>
.drawer-transition {
  transition: width 0.3s ease-out; /* Add a smooth transition */
}

.main-expanded {
  margin-left: 256px; /* Width of the expanded navigation drawer */
}

.main-mini {
  margin-left: 80px; /* Width of the mini-variant navigation drawer */
}
.custom-active {
  background-color: #de2568 !important;
  color: white !important;
  transition: background-color 0.3s ease-in-out; /* Add transition for background-color */
}

.custom-list {
  .v-list-group__header,
  .v-list-item__title,
  .v-list-item__subtitle,
  .v-list-item__icon {
    color: white !important;
  }

  .v-list-group__header__append-icon {
    // color: red !important;
  }

  .v-list-group__header__append-icon .v-list-group--active {
    background-color: transparent;
  }
}

.dashboard-container {
  background-image: url("../../assets/Ridery.png") !important;
  background-color: #000522;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
}

.theme-container {
  min-height: 100vh;
  background-color: #eeeeee;
}
.dark-theme-container {
  background-color: #000010;
}

.blur-nav {
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ) !important; /* Set a background color with opacity */
  backdrop-filter: blur(8px);
}
.blur-nav-dark {
  background-color: rgba(
    0,
    4,
    33,
    0.8
  ) !important; /* Set a background color with opacity */
  // opacity: 0.95;
  backdrop-filter: blur(8px);
}
</style>
