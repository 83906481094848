// store/modules/auth.js

const state = {
  user: {
    isLoggedIn: false,
    token: null,
    userDetails: {},
  },
};

const mutations = {
  SET_USER(state, user) {
    state.user = { ...state.user, ...user };
  },
};

const actions = {
  setUser({ commit }, user) {
    commit("SET_USER", user);
  },
};

const getters = {
  user: (state) => state.user,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
