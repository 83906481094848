export default [{
    path: "/flyers",
    component: () => import(/* webpackChunkName: "user" */ "@/layouts/user/UserLayout.vue"),
    children: [
        {
            path: "",
            name: "flyers",
            component: () => import( /* webpackChunkName: "flyers" */ "@/views/flyers/Flyers.vue"),
        }
    ]
}]