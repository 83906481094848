// store/modules/fly.js

import { FLIGHT_TYPES } from "@/utils"

/**
 * Fly state
 * @typedef {Object} state
 */
const state = {
    takeoffAirport: null,
    landingAirport: null,
    takeoffDatetimeValue: null,
    returnDatetimeValue: null,
    emptyLegDatetimeValue: null,
    passengersCounts: {
        adults: 0,
        childrens: 0,
        babies: 0,
        pets: 0,
    },
    departureAirports: [],
    landingAirports: [],
    selectedRoute: null,
    flightType: FLIGHT_TYPES.DEPARTURE,
    settings: null
}

const mutations = {
    INCREASE_PASSENGERS_AMOUNT(state, passenger) {
        state.passengersCounts[passenger] = state.passengersCounts[passenger] + 1
    },
    DECREASE_PASSENGERS_AMOUNT(state, passenger) {
        if (state.passengersCounts[passenger] <= 1) {
            state.passengersCounts[passenger] = 0
        } else {
            state.passengersCounts[passenger] = state.passengersCounts[passenger] - 1
        }
    },
    SET_SETTINGS(state, settings) {
        state.settings = settings
    },
    SET_TAKEOFF(state, takeoff) {
        state.takeoffAirport = takeoff
    },
    SET_DEPARTURE_AIRPORTS(state, departureAirports) {
        state.departureAirports = departureAirports
    },
    SET_LANDING_AIRPORTS(state, landingAirports) {
        state.landingAirports = landingAirports
    },
    SET_LANDING(state, landing) {
        state.landingAirport = landing
    },
    SET_TAKEOFF_DATETIME(state, takeoffDatetime) {
        state.takeoffDatetimeValue = takeoffDatetime
    },
    SET_RETURN_DATETIME(state, returnDatetime) {
        state.returnDatetimeValue = returnDatetime
    },
    SET_EMPTY_LEG_DATETIME(state, emptyLegDatetime) {
        state.emptyLegDatetimeValue = emptyLegDatetime
    },
    SET_SELECTED_ROUTE(state, route) {
        state.selectedRoute = JSON.stringify(route)
    },
    SET_FLIGHT_TYPE(state, flightType) {
        state.flightType = flightType
    },
    CLEAR_STATES(state) {
        state.takeoffAirport = null
        state.landingAirport = null
        state.takeoffDatetimeValue = null
        state.returnDatetimeValue = null
        state.emptyLegDatetimeValue = null
        state.passengersCounts = {
            adults: 0,
            childrens: 0,
            babies: 0,
            pets: 0,
        } 
        state.departureAirports = []
        state.landingAirports = []
        state.flightType = FLIGHT_TYPES.DEPARTURE,
        state.selectedRoute = null
    }
}

const actions = {
    increasePassengersAmount({ commit }, passenger) {
        commit('INCREASE_PASSENGERS_AMOUNT', passenger)
    },
    decreasePassengersAmount({ commit }, passenger) {
        commit('DECREASE_PASSENGERS_AMOUNT', passenger)
    },
    setSettings({ commit }, settings) {
        commit('SET_SETTINGS', settings)
    },
    setDepartureAirports({ commit }, departureAirports) {
        commit('SET_DEPARTURE_AIRPORTS', departureAirports)
    },
    setLandingAirports({ commit }, landingAirports) {
        commit('SET_LANDING_AIRPORTS', landingAirports)
    },
    setTakeoff({ commit }, takeoff) {
        commit('SET_TAKEOFF', takeoff)
    },
    setLanding({ commit }, landing) {
        commit('SET_LANDING', landing)
    },
    setTakeoffDatetime({ commit }, takeoffDatetime) {
        commit('SET_TAKEOFF_DATETIME', takeoffDatetime)
    },
    setReturnDatetime({ commit }, returnDatetime) {
        commit('SET_RETURN_DATETIME', returnDatetime)
    },
    setEmptyLegDatetime({ commit }, emptyLegDatetime) {
        commit('SET_EMPTY_LEG_DATETIME', emptyLegDatetime)
    },
    setSelectedRoute({ commit }, route) {
        commit('SET_SELECTED_ROUTE', route)
    },
    setFlightType({ commit }, flightType) {
        commit('SET_FLIGHT_TYPE', flightType)
    },
    clearStates({ commit }) {
        commit('CLEAR_STATES')
    }
}

const getters = {
    getTakeoff: (state) => state.takeoffAirport,
    getLanding: (state) => state.landingAirport,
    getTakeoffDatetime: (state) => state.takeoffDatetimeValue,
    getReturnDatetime: (state) => state.returnDatetimeValue,
    getEmptyLegDatetime: (state) => state.emptyLegDatetimeValue,
    getPassengers: (state) => state.passengersCounts,
    getTotalPassengers: (state) => {return state.passengersCounts.adults + state.passengersCounts.childrens},
    getDepartureAirportName: (state) => (id) => {
        return state.departureAirports.find(airport => airport.value === id)?.text
    },
    getLandingAirportName: (state) => (id) => {
        return state.landingAirports.find(airport => airport.value === id)?.text
    },
    getAirportInfo: (state) => (id) => {
        return state.airports.find(airport => airport.value === id)
    },
    getSelectedRoute: (state) => {
        return JSON.parse(state.selectedRoute)
    },
    getFlightType: (state) => state.flightType,
    getSelectedAirplaneFeaturedPhoto: (state) => {
        const route = JSON.parse(state.selectedRoute)
        const featuredPhoto = route?.photos.find(photo => photo.featured)
        return featuredPhoto || null
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
