export default [{
    path: "",
    component: () => import(/* webpackChunkName: "user" */ "@/layouts/user/UserLayout.vue"),
    children: [
        {
            path: "privacy-politics",
            name: "privacy-politics",
            component: () => import( /* webpackChunkName: "privacyPolitics" */ "@/views/legals/PrivacyPolitics.vue"),
        },
        {
            path: "security-politics",
            name: "security-politics",
            component: () => import( /* webpackChunkName: "securityPolitics" */ "@/views/legals/SecurityPolitics.vue")
        },
        {
            path: "terms-conditions",
            name: "terms-conditions",
            component: () => import( /* webpackChunkName: "termsConditions" */ "@/views/legals/TermsConditions.vue")
        },
        {
            path: "travel-considerations",
            name: "travel-considerations",
            component: () => import( /* webpackChunkName: "travelConsiderations" */ "@/views/legals/TravelConsiderations.vue")
        },

    ]
}]